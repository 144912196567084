import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import LinkWrap from 'components/LinkWrap'
import { strings } from 'js/strings'

export default function SitemapTemplate({ data }) {
  const page = data.wpPage
  let { allWpPage, allWpPost, allWpCategory } = data
  allWpCategory = allWpCategory.nodes.filter(
    (node) => node.slug !== 'uncategorized'
  )

  let sections = [
    {
      wpType: 'page',
      title: strings.pages,
      items: allWpPage.nodes.filter(
        (page) =>
          page.template.templateName !== 'Solutions Single' &&
          page.template.templateName !== 'Industries Single' &&
          page.template.templateName !== 'Sitemap' &&
          page.template.templateName !== 'Thank You' &&
          page.template.templateName !== '404'
      ),
    },
    {
      wpType: 'page',
      title: strings.solutions,
      items: allWpPage.nodes.filter(
        (page) => page.template.templateName === 'Solutions Single'
      ),
    },
    {
      wpType: 'page',
      title: strings.industries,
      items: allWpPage.nodes.filter(
        (page) => page.template.templateName === 'Industries Single'
      ),
    },
  ]

  allWpCategory.forEach((cat) => {
    if (cat.slug === 'industry-news') return
    sections.push({
      wpType: 'post',
      title: cat.name,
      items: allWpPost.nodes.filter((post) =>
        post.categories.nodes.find((category) => category.slug === cat.slug)
      ),
    })
  })

  return (
    <Layout
      bodyClassName='page page-id-26058 sitemap app-data index-data singular-data page-data page-26058-data page-sitemap-data'
      page={page}
    >
      <div className='page-default'>
        <div className='page-default__content'>
          <div id='html_sitemap' className='has-multiple-columns'>
            {sections.map((section, i) => (
              <Section key={i} content={section} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

const Section = ({ content }) => {
  const { wpType, title, items } = content
  return (
    <div className='sitemap-column sitemap-columns-2 sitemap-posttypes sitemap-posttype-case_study'>
      <div className='html-sitemap-column'>
        <h2>{title}</h2>
        <ul className='html-sitemap-list'>
          {items.map((item, i) => (
            <Item key={i} wpType={wpType} content={item} />
          ))}
        </ul>
      </div>
    </div>
  )
}

const Item = ({ content, wpType }) => {
  const { title, uri, slug, categories } = content

  let to
  if (wpType === 'page') {
    to = uri
  }
  if (wpType === 'post') {
    const firstCategory = categories.nodes[0]
    to = `/${firstCategory.slug}/${slug}`
  }

  return (
    <li className='html-sitemap-list-item html-sitemap-post-22133'>
      <LinkWrap to={to} title={title}>
        {title}
      </LinkWrap>
    </li>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
    }
    allWpPage {
      nodes {
        uri
        title
        template {
          templateName
        }
      }
    }
    allWpPost {
      nodes {
        slug
        title
        categories {
          nodes {
            name
            slug
          }
        }
      }
    }
    allWpCategory(sort: { fields: description }) {
      nodes {
        name
        slug
      }
    }
  }
`
